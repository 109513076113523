.reactMarkDown {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
    text-align: justify
}
@media print, screen and (max-width: 600px) {
    .reactMarkDown {
        max-width: 90%
    }
}

.reactMarkDown a {
    color: inherit;
}
